import candidates2024Image from "./Images/CandidatesScreenshotLastRound.png";
import graphVideoImage from "./Images/GraphVideoScreenshot.png";
import blurredCode from "./Images/BlurredCode.png";
import gameGraphs from "./Images/GameGraphsScreenshot.png";
import simpleTournament from "./Images/SimpleCandidatesGraph.png";
import complexTournament from "./Images/NewTournamentGraph.png";
import nextGen from "./Images/NextGenStatsScreenshot.png";
import womensBracket from "./Images/WomensBracket.png";
import polymarket from "./Images/Polymarket.png";
import paulMorphy from "./Images/PaulMorphy.png";
import nakamuraDuda from "./Images/NakamuraDuda.png";
import round5 from "./Images/Round5Graph.png";
import praggNepo from "./Images/PraggNepo.png";

const howItsMadeBlog = {
    title: "How It's Made: The Model",
    thumbnail: blurredCode,
    date: "November 22, 2024",
    link: "1",
    content: [
        "Welcome to my first blog post here at ChessAssess! For a long time now, I've been meaning to put together a write-up explaining in a bit more detail how a lot of the technical projects here were created, so that's what I'll be attempting here.",
        "I'd like to step through several different pieces of my chess projects, so I'll break this into sections for each piece. This blog will be specifically about how I model tournaments. I hope to write more about the other chess projects I've created here on ChessAssess and elsewhere in the future.",
        { type: "header", text: "Tournament Simulations" },
        "The first thing I want to discuss is my Monte Carlo Simulation model for chess tournaments. This is nothing unique; in fact, I was inspired a lot here by @ChessNumbers and @Pawnalyze, who use their own simulation models and produce some awesome content themselves.",
        "The main idea is to take a chess tournament and for every game, if we know the odds of a white win, draw, or black win (usually based on player ratings, which can update after every game), we can use some random number generator magic to randomly select one of these results. If we do that for every game of the tournament, we have successfully simulated one possible tournament outcome. If we do this a whole lot more, maybe 100,000 times, we can get a pretty good estimate for how often each player is expected to win the tournament.",
        { type: "image", src: simpleTournament, caption: "my Candidates 2024 graph if I all I did was run simulations between rounds" },
        { 
            type: "link",
            textStart: "In the past I've used ",
            linkText: "this resource",
            textEnd: " from François Labelle, which derives a formula for calculating draw chances given two ratings.",
            href: "https://wismuth.com/elo/calculator.html"
        },
        "More recently, I've used my own model which estimates win / draw / loss probabilities at the first move, explained more in the following section. I've also experimented with different additions such as accounting for players' head-to-head game history,  as well as updating odds to more accurately represent players in 'must-win' situations, where the chance of a decisive game would be expected to increase. I plan on experimenting more with these and other features in the future as I cover more tournaments.",
        { type: "header", text: "Estimating In-Game Probabilities, Move-by-Move" },
        "Tournament simulations are already pretty cool on their own, and they can give us pretty good Elo-based estimates of players' chances to win the tournament after each round is played. But what if we want to estimate each player's tournament victory chances while a round is currently being played?",
        "That's where my Logistic Regression Model comes in. Theoretically, we should be able to estimate win / draw / loss chances in any given chess position given both players' ratings, engine evaluation of the position, the time left on each player's clock, and some miscellaneous other data about the position.",
        { type: "image", src: gameGraphs, caption: "game graphs from Round 13 of the 2024 Candidates" },
        "It took a long time to gather all the training data I needed. Originally, I had sampled random positions from a database of master games where both players had ratings >2600. For each position, I ran my own Stockfish locally to get an engine evaluation of every position I sampled. Eventually I might upload this data somewhere publicly, so let me know if you are interested in that.",
        "Unfortunately, I could not find a database of a good amount of top master games that included clock times for each move, so for a while, I considered leaving time out of the equation. Finally I ended up using a work-around: I trained the model on the data from the database I had, with a little twist. I downloaded a bunch of master-level chess games from Lichess (which includes clock times for each move), and used this to calculate how a proportional time advantage translates to a rating advantage. Then I could just cheat and convert clock times to an Elo-adjustment, apply the new ratings, and run it through the model I trained. This worked surprisingly well but was still not ideal. More recently I have used games downloaded from top level tournaments broadcast on Lichess to train a new model with clock times included, but I still wanted to share this hacky workaround since I thought it was interesting.",
        { type: "header", text: "Bringing It All Together" },
        "With both of these models combined together, we can do some pretty cool stuff. Here's how: First, I run a set of simulations for every possible scenario in a given round. For example in a Candidates Tournament of 8 players, that's 4 games in a round. Each game has 3 possible results: 1-0, 1/2-1/2, 0-1. That's 12 sets of simulations, each of which has a chance of occurring that can be calculated by applying the Logistic Regression Model to the starting positions in each of the 4 games. With the models combined, I can simply wait for a move to be played in any of the games, re-apply the Logistic Regression Model, re-calculate the chances of each scenario, and the model will spit out its updated percentages for each player's tournament victory chances.",
        { type: "image", src: complexTournament, caption: "my official 2024 Candidates graph with both models combined" },
        "Now I can produce some pretty cool visualizations which you can see by checking out the different tournament pages here at ChessAssess. You can see just how much complexity and how many details are shown in the combined-model graph as compared to the basic simulation-only graph. I am especially happy with this video I recently created, based on the output data from the 2024 FIDE Candidates Tournament.",
        { type: "video", src: "vRFL3VB94YY", caption: "my recent video of the FIDE Candidates Tournament 2024" },
    ]
}

const futurePlansBlog = {
    title: "Future Plans",
    thumbnail: graphVideoImage,
    date: "November 23, 2024",
    link: "2",
    content: [
        "I've been a big football fan for as long as I can remember. I've always especially loved cool sports statistics and data visualizations, from ESPN's GameCast and probability graphs to FiveThirtyEight's March Madness coverage. Stuff like that makes sports so much more fun to follow for me. That's why I made ChessAssess: I want to bring that same excitement through the combination of sport and data to chess. My ultimate vision is a chess broadcast with the same level of stats and data visualizations as something like NFL Next Gen Stats, breaking down the skill barrier that keeps many people from watching chess by engaging the audience with accessible, easy-to-understand statistics and visuals. I'll use this post to talk about some various ideas I have to work towards this goal.",
        { type: "image", src: nextGen, caption: "an example of an NFL Next Gen Stats visualization showing Russell Wilson's passing" },
        { type: "header", text: "Win Probability Visualizations" },
        "Most modern chess broadcasts these days include an engine evaluation bar next to the board, which has greatly enhanced the appeal of chess to a wider audience by allowing them to be able to assess the current state of the game. However, the problem is the engine eval number is completely meaningless to new viewers; What does +1.5 even mean to a new player? Even if you take the number away, what does it mean when the white bar is 5 squares high while the black bar is 3? Instead, having bars on the side showing probabilities of white win / draw / black win could be much clearer, especially when combined with the further probability visualizations that I use at ChessAssess.com. Percentages have very clear meanings and can even contain more underlying information. For example, a complex middlegame position with a 0.00 evaluation might have a much lower draw percentage than a dry endgame with the same evaluation.",
        { type: "image", src: nakamuraDuda, caption: "eval bar vs probability bar in Nakamura - Duda, round 13 of the 2022 Candidates" },
        "In addition, win probability graphs can tell their own stories that can enhance an audience's viewing experience. For example, a viewer watching Round 5 of the 2024 Candidates Tournament would have surely been engaged by the storyline of the Praggnanandhaa - Nepomniachtchi game. Pragg's exciting opening preparation led to his advantage after he sacrifices material, but he soon squandered his edge and the game fizzled out into a drawn endgame. But how big of a chance did Pragg miss? How much of an impact would his and Nepo's tournament chances have felt had he converted? These questions can be answered with probability graphs of both the games and the tournament as a whole.",
        { type: "image", src: praggNepo, caption: "the game graph of Pragg - Nepo, 2024 Candidates" },
        { type: "image", src: round5, caption: "how the Pragg - Nepo game can be seen in the tournament graph for round 5" },
        { type: "header", text: "Interactive Brackets for Knockout Tournaments" },
        "In the US, March Madness, the annual single-elimination knockout tournament for college basketball, is huge. I don't know many people who watch college basketball regularly, but somehow nearly everyone I know who is even mildly into sports fills out a March Madness bracket every year, where they make their picks and predict a winner. Everyone wants to enter to win either a prize or bragging rights among friends. After filling out a bracket, suddenly the viewer's experience is transformed. Instead of following one or two teams, or waiting until the final four to see who might come out on top, suddenly every single game feels important and exciting, since it could make or break your bracket.",
        { type: "image", src: womensBracket, caption: "my interactive bracket for the 2023 FIDE Womens World Cup" },
        {
            type: "link",
            textStart: "I don't see any reason why we shouldn't have similar competitions for chess knockout tournaments, such as my personal favorite, the FIDE World Cup. That's why I created ",
            linkText: "this interactive probability bracket",
            textEnd: " for the tournament in 2023. I want to have bracket contests with updated leaderboards after each round, all combined with live probability estimates that update every move.",
            href: "../worldcup",
        },
        { type: "header", text: "\"Market\"-esque Player Trading" },
        {
            type: "link",
            textStart: "When GMHikaru watched ",
            linkText: "my recent Candidates 2024 video,",
            textEnd: " his first thought was about the potential for sports betting in chess. You can imagine why: watching the lines on the graph jump up and down and fluctuate as games were played no-doubt reminded him of trading, whether it be stocks or other markets such as PolyMarket.",
            href: "https://www.youtube.com/watch?v=vRFL3VB94YY",
        },
        { type: "image", src: polymarket, caption: "Polymarket's betting odds for the 2025 Superbowl" },
        "I've been thinking about a game based on this concept for a while, probably with play-money or a points-based system. Imagine a viewer can 'buy' Fabiano stocks before a tournament they think he's likely to win, or even during a game they think he has an advantage in. This could be turned into a game where players try to build up their points by making their best 'trades'. There is potential here such that, even in an inefficient market without a lot of bettors, the starting prices can easily be based on the probability graphs already produced here at ChessAssess.",
        { type: "header", text: "Engaging Video Content" },
        "Turning data visualizations into videos can make them come to life. Watching a tournament progress in the form of a video of every player's chances of winning after each move is played can make the viewer feel more immersed in the tournament itself, as they watch the probabilities fluctuate during the games.",
        {
            type: "link",
            textStart: "Take ",
            linkText: "this video",
            textEnd: " of the top chess players over time by Abacaba as an example. Made by a non-chess creator and viewed by an incredible 2.8 million people, what makes this video so engaging? Part of it is surely the history, looking back and recognizing names from different opening variations is pretty cool in itself. But so many of these viewers were not even chess fans; what made them so interested in watching random lines go up and down?",
            href: "https://www.youtube.com/watch?v=z2DHpW79w0Y",
        },
        { type: "image", src: paulMorphy, caption: "screenshot from the Abacaba video of Paul Morphy at the top" },
        "In my opinion, it's the combination of video and detailed, interesting data that engages viewers of all types. This would not be nearly as interesting if it were a static graph that took up your entire screen and didn't move an inch. Watching the players' ratings move up and down, seeing who can capture the #1 spot, watching the players grow old and seeing their lines eventually disappear just for someone else to take their place - there are many examples of immersive features of a video like this.",
        "I think there's a great amount of potential here for more engaging content with data visualizations in video form, so you can expect more videos in the future like the one below for the 2024 Candidates Tournament. I'm envisioning tournament graphs with live chess boards with play-by-plays showing the biggest blunders or critical moves of every game. All of this is possible with the data here and I will be pushing in this direction in the near future.",
        { type: "video", src: "vRFL3VB94YY", caption: "my recent video of the FIDE Candidates Tournament 2024" },
        { type: "header", text: "Conclusion" },
        "While I won't have time to cover the 2024 World Championship between Ding Liren and Gukesh D live due to travel and holidays, I will still be making content after the fact by breaking down each game, visualizing the tournament graph and other statistics, and perhaps even creating a video. I also have plans for a rebuild of the site itself; if you didn't know, ChessAssess was one of my first web-dev projects ever. Although I'm a software engineer by trade, I've never been much of a web developer. Still, I've learned a lot since I first made this site and there's a lot I'd do differently a second time around. There is a lot in the works, so stay tuned for more chess coverage and data visualizations to come."
    ]
}

export const blogs = [
    futurePlansBlog,
    howItsMadeBlog,
];
