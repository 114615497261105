import React from 'react'
import fischer from '../Images/fischerasciiscreenshot.png'
import axios from 'axios'
import qs from 'qs'
import ReactGA from 'react-ga'
import { blogs } from "../blogsData"

export default function Blog({ title, content, author = "Johnathan Browning", date }) {
    return (
      <div
        className="blog"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
          gap: "20px",
        }}
      >
        {/* Blog Content */}
        <div className='blog-content' style={{ flex: 3, textAlign: "center", margin: "0 auto", alignItems: 'center' }}>
            <div style={{ maxWidth: "800px", margin: '0 auto' }}>
                {/* Blog Header */}
                <div style={{ textAlign: "left", marginBottom: "20px" }}>
                    <h2 style={{ margin: "0" }}>{title}</h2>
                    <p style={{ margin: "5px 0", fontSize: "14px", color: "#999" }}>
                        By {author} - {date}
                    </p>
                </div>
                {/* Blog Body */}
                {content.map((item, index) => {
                    if (typeof item === "string") {
                        return (
                            <p
                                key={index}
                                style={{ textAlign: "left", marginBottom: "20px", lineHeight: "1.6" }}
                            >
                                {item}
                            </p>
                        );
                    }
                    if (item.type === "link") {
                        return (
                            <p key={index} style={{ textAlign: "left", marginBottom: "20px", lineHeight: "1.6" }}>
                                {item.textStart}{" "}
                                <a
                                    href={item.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: "var(--link)",          // Inherits the color of the surrounding text
                                        textDecoration: "none",    // Removes the underline
                                        fontWeight: "normal",      // Ensures the link is not bold
                                        fontSize: 'inherit',
                                        padding: '0',
                                        margin: '0',
                                    }}
                                >
                                    {item.linkText}
                                </a>{" "}
                                {item.textEnd}
                            </p>
                        )
                    }
                    if (item.type === "image") {
                        return (
                            <div key={index} style={{ marginBottom: "20px" }}>
                                <img
                                    src={item.src}
                                    alt={`Content Image ${index + 1}`}
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "500px",
                                        margin: "0 auto",
                                        display: "block",
                                        borderRadius: "8px",
                                    }}
                                />
                                {item.caption && (
                                    <p
                                        style={{ marginTop: "5px", fontSize: "14px", color: "#888" }}
                                    >
                                        {item.caption}
                                    </p>
                                )}
                            </div>
                        );
                    }
                    if (item.type === "video") {
                        return (
                            <div key={index} style={{ marginBottom: "20px" }}>
                                <iframe
                                    src={`https://www.youtube.com/embed/${item.src}`}
                                    title={`Video Content ${index + 1}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{
                                        maxWidth: "100%", // Ensures the width adjusts responsively
                                        height: "500px", // Limits the height
                                        aspectRatio: "16/9", // Maintains the video's aspect ratio
                                        margin: "0 auto",
                                        display: "block",
                                        borderRadius: "8px",
                                    }}
                                ></iframe>
                                {item.caption && (
                                    <p
                                        style={{
                                            marginTop: "5px",
                                            fontSize: "14px",
                                            color: "#888",
                                        }}
                                    >
                                        {item.caption}
                                    </p>
                                )}
                            </div>
                        );
                    }
                    if (item.type === "header") {
                        return (
                            <h3
                                key={index}
                                style={{
                                    textAlign: "left",
                                    margin: "20px 0 10px",
                                    fontSize: "18px",
                                }}
                            >
                                {item.text}
                            </h3>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
  
        {/* Blog Links Sidebar */}
        <div
            style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "10px", // Reduced gap
            }}
        >
            <h3>Blogs</h3>
            {blogs.map((blog, index) => (
                <a
                className="blogs-box"
                key={index}
                href={`/blog/${blog.link}`} // Ensures the correct path
                style={{
                    borderRadius: '0',
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <img
                    src={blog.thumbnail}
                    alt={blog.title}
                    style={{
                        width: "60px",
                        height: "60px",
                        objectFit: "cover",
                        borderRadius: "5px",
                    }}
                />
                <div>
                    <h4 style={{ textAlign: 'left', margin: "0", fontSize: "14px" }}>{blog.title}</h4>
                    <p style={{ textAlign: 'left', margin: "0", fontSize: "12px", color: "#888" }}>
                        {blog.date}
                    </p>
                </div>
            </a>
            ))}
        </div>
      </div>
    );
}